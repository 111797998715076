<template>
  <email-templates-index :fields="fields" :filters="filters" :query="query" />
</template>

<script>
import Vue from 'vue';
import EmailTemplate from '~/models/EmailTemplate';
import EmailTemplatesIndex from '~/pages/shared/email-templates/EmailTemplatesIndex';

export default Vue.extend({
  components: { EmailTemplatesIndex },

  middleware: 'employee',

  data: () => ({
    query: () => new EmailTemplate(),

    filters: [],

    fields: ['label', 'created_at', 'actions'],
  }),
});
</script>
